/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-16 11:29:31
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';
import { eduLevel, lookStatus, getType } from '@/utils/options';
export var models = genModels(function (genRules, ctx) {return [
  {
    kname: 'title',
    itemProps: {
      label: '标题：',
      rules: [genRules('require')] },

    component: {
      props: {
        maxlength: 20 } } },



  {
    kname: 'desc',
    itemProps: {
      label: '描述：',
      rules: [genRules('require')] },

    component: {
      props: {
        maxlength: 20 } } },



  {
    kname: 'createAt',
    itemProps: {
      label: '创建时间：',
      rules: [genRules('require')] },

    component: {
      props: {
        maxlength: 20 } } }];});






// form 默认值
export var formData = function formData() {return {};};